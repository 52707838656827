import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteDistributionEvent, getEventList } from "../../../store/actions";
import moment from "moment/moment";
import AddSponsorToBlockReminderListModal from "./AddSponsorToBlockReminderListModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const BlockReminders = () => {
  const dispatch = useDispatch();
  const { eventList } = useSelector((state) => state.distributionEvent);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  useEffect(() => {
    dispatch(getEventList());
  }, []);

  return (
    <>
      <div className="HeaderTextCMS">
        Block Payment Reminders
        <div className="Filters">
          <btn
            className="btn btn-success"
            to="create"
            onClick={() => setOpenAddModal(true)}
          >
            ADD
          </btn>
        </div>
      </div>
      <AddSponsorToBlockReminderListModal
        show={openAddModal}
        handleClose={() => setOpenAddModal(false)}
      />
      <DeleteConfirmationModal
        show={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      />
      <div className="WhiteContent">
        <table width="100%">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Status</th>
              <th width="130">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Navindu Dilshan</td>
              <td>navindudilshan6@gmail.com</td>
              <td>Active</td>
              <td>
                <Link
                  className="btn btn-info mr-2"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <i className="fa fa-pencil"></i>
                </Link>
                <Link className="btn btn-success">
                  <i className="fa fa-eye"></i>
                </Link>
              </td>
            </tr>
            {/* {eventList?.data?.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{moment(item.createdAt).format("MMMM D, YYYY")}</td>
                <td>{moment(item.date).format("MMMM D, YYYY")}</td>
                <td>
                  <Link className="btn btn-info mr-2" to={`edit/${item.id}`}>
                    <i className="fa fa-pencil"></i>
                  </Link>
                  <Button
                    variant="danger"
                    onClick={()=>dispatch(deleteDistributionEvent(item.id))}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BlockReminders;
