import React from "react";
import { Button, Col, FormGroup, FormLabel, Modal, ModalBody, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select from "react-select";

const AddSponsorToBlockReminderListModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} size={"lg"} animation={false}>
      <ModalHeader closeButton onHide={handleClose}>
        <Modal.Title>Add sponsor to block payment reminder list</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <FormLabel>Sponsor Name</FormLabel>
              {/* <FormControl /> */}
              <Select
                size="sm"
                isClearable
                // options={accounts?.map((e) => ({
                //   label: e.name,
                //   value: e.id,
                // }))}
                //value={filters.accounts}
                //onChange={(e) => setFilters({ ...filters, accounts: e })}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>Close</Button>
          <Button type="submit">Add</Button>
        </Modal.Footer>
    </Modal>
  );
};

export default AddSponsorToBlockReminderListModal;
